<template>
  <div class="row">
    <div
      v-show="withTitle"
      class="flex xs12"
    >
      <slot name="title">
        <h6>{{ $t('layout.location') }}</h6>
      </slot>
    </div>
    <div
      class="flex xs12"
      :class="flexSize"
      v-if="filter('regions')"
    >
      <va-select
        v-model="selectedRegion"
        :label="$t('layout.form.regionInput')"
        :no-options-text="$t('layout.empty')"
        :loading="isLoading.regions"
        :disabled="disabled || loading"
        :options="regionsList"
        text-by="i18nName"
        searchable
      />
    </div>
    <div
      class="flex xs12"
      :class="flexSize"
      v-if="filter('countries')"
    >
      <va-select
        v-model="selectedCountry"
        :label="$t('layout.form.countryInput')"
        :no-options-text="$t('layout.empty')"
        :loading="isLoading.countries"
        :disabled="disabled || loading || !selectedRegion || selectedRegion.id === 0"
        :options="countriesList"
        text-by="i18nName"
        searchable
      />
    </div>
    <div
      class="flex xs12"
      :class="flexSize"
      v-if="filter('districts')"
    >
      <va-select
        v-model="selectedDistrict"
        :label="$t('layout.form.districtInput')"
        :no-options-text="$t('layout.empty')"
        :loading="isLoading.districts"
        :disabled="disabled || loading || !selectedCountry || selectedCountry.id === 0"
        :options="districtsList"
        text-by="i18nName"
        searchable
      />
    </div>
    <slot></slot>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'location-bar',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    withTitle: {
      type: Boolean,
      default: true,
    },
    withFilters: {
      type: Array,
      default: () => [],
    },
    flexSize: {
      type: String,
      default: 'md4',
    },
    allSelectors: {
      type: Array,
      default: () => [],
    },
    initialDistrict: {
      type: Object,
      required: false,
    },
    initialCountry: {
      type: Object,
      required: false,
    },
    initialRegion: {
      type: Object,
      required: false,
    },
  },
  computed: {
    ...mapGetters(['currentUser']),
  },
  data () {
    return {
      isLoading: {
        regions: false,
        countries: false,
        districts: false,
      },
      isError: {
        regions: false,
        countries: false,
        districts: false,
      },
      regionsList: [],
      selectedRegion: '',
      countriesList: [],
      selectedCountry: '',
      districtsList: [],
      selectedDistrict: '',
    }
  },
  watch: {
    withFilters (val) {
      if (val.length === 0) return
      this.initialData()
    },
    initialDistrict (val) {
      if (!val) {
        this.selectedDistrict = ''
        return
      }

      val.i18nName = this.$t(val.name)
      this.selectedDistrict = val
      if (this.selectedDistrict.country) {
        this.selectedDistrict.country.i18nName = this.$t(this.selectedDistrict.country.name)
        this.selectedCountry = this.selectedDistrict.country
        if (this.selectedCountry.region) {
          this.selectedCountry.region.i18nName = this.$t(this.selectedCountry.region.name)
          this.selectedRegion = this.selectedCountry.region
        }
      }
    },
    initialCountry (val) {
      if (!val) {
        this.selectedCountry = ''
        return
      }

      val.i18nName = this.$t(val.name)
      this.selectedCountry = val
      if (this.selectedCountry.region) {
        this.selectedRegion = this.selectedCountry.region
      }
    },
    initialRegion (val) {
      val.i18nName = this.$t(val.name)
      this.selectedRegion = val || ''
    },
    'currentUser.permissions' (val) {
      this.initialData()
    },
    selectedRegion (val) {
      if (!val || val === '') {
        this.regionRemoved()
      } else { this.regionSelected(val) }
    },
    selectedCountry (val) {
      if (!val || val === '') {
        this.countryRemoved()
      } else { this.countrySelected(val) }
    },
    selectedDistrict (val) {
      this.districtSelected(val)
    },
  },
  created () {
    this.initialData()
  },
  methods: {
    hasAll (selector) {
      return this.allSelectors.some(s => s === selector)
    },
    filter (filter) {
      return this.withFilters.findIndex(f => filter === f) > -1
    },
    async initialData () {
      if (this.filter('regions')) {
        this.asyncFindRegion('&sort=name&direction=asc')
      }
    },
    async asyncFindRegion (query) {
      if ((!this.currentUser.can('Regions', 'index') || !this.currentUser.super) && this.currentUser.region) {
        this.regionsList = [
          { ...this.currentUser.region, i18nName: this.$t(this.currentUser.region.name) },
        ]
        return
      }

      await this.asyncFind(query, 'regions', 'regions')
      if (this.isError.regions) {
        this.asyncFindRegion(query)
        return
      }
      for (const c of this.regionsList) {
        c.i18nName = this.$t(c.name)
      }

      if (this.hasAll('regions')) {
        this.regionsList.unshift({
          id: 0,
          i18nName: this.$t('layout.selects.all_regions'),
        })
      }
    },
    async asyncFindCountry (query) {
      if (!this.currentUser.can('Countries', 'index') && this.currentUser.country) {
        this.countriesList = [
          { ...this.currentUser.country, i18nName: this.$t(this.currentUser.country.name) },
        ]
        this.currentUser.country.i18nName = this.$t(this.currentUser.country.name)
        this.countrySelected(this.currentUser.country)
        return
      }

      if (this.selectedRegion) {
        query += '&region=' + this.selectedRegion.id
      }
      await this.asyncFind(query, 'countries', 'countries')
      if (this.isError.countries) {
        this.asyncFindCountry(query)
      }
      for (const c of this.countriesList) {
        c.i18nName = this.$t(c.name)
      }

      if (this.hasAll('countries')) {
        this.countriesList.unshift({
          id: 0,
          i18nName: this.$t('layout.selects.all_countries'),
        })
      }
    },
    async asyncFindDistrict (query) {
      if (!this.currentUser.can('Districts', 'index') && this.currentUser.district) {
        this.districtsList = [
          { ...this.currentUser.district, i18nName: this.$t(this.currentUser.district.name) },
        ]
        this.currentUser.country.i18nName = this.$t(this.currentUser.district.name)
        this.districtSelected(this.currentUser.district)
        return
      }

      if (this.selectedCountry) {
        query += '&country=' + this.selectedCountry.id
      }

      // Param
      query += '&limit=100'
      await this.asyncFind(query, 'districts', 'districts')
      if (this.isError.districts) {
        this.asyncFindDistrict(query)
      }
      for (const c of this.districtsList) {
        c.i18nName = this.$t(c.name)
      }

      if (this.hasAll('districts')) {
        this.districtsList.unshift({
          id: 0,
          name: this.$t('layout.selects.all_districts'),
        })
      }
    },
    async asyncFind (query, type, route) {
      this.isLoading[type] = true
      this.isError[type] = false

      if (!query.includes('&sort=')) {
        query += '&sort=name'
      }
      if (!query.includes('&direction=')) {
        query += '&direction=asc'
      }

      let response = false
      try {
        response = await this.$http.get(`/${route}?q=${query}`)
      } catch (err) {
        this.isLoading[type] = false
        this.isError[type] = true
        return
      }

      this[type + 'List'] = response.data.data || []
      this.isLoading[type] = false
    },
    regionSelected (region) {
      this.selectedRegion = region
      this.$emit('selected-region', this.selectedRegion)
      if (!this.filter('countries')) return

      if (this.currentUser.can('Countries', 'index')) {
        this.asyncFindCountry(`&sort=name&direction=asc&region=${region.id}`)
      } else {
        this.isLoading.countries = true
        if (this.currentUser.country) {
          this.asyncFindCountry(`&sort=name&direction=asc&region=${region.id}`)

          /* this.countriesList = [
            this.currentUser.country,
          ] */
          this.selectedCountry = this.currentUser.country
          this.countrySelected(this.currentUser.country)
        }
        this.isLoading.countries = false
      }

      if (!!this.selectedCountry && this.selectedCountry.region_id !== region.id) {
        this.selectedCountry = ''
        this.$emit('selected-country', this.selectedCountry)
        this.districtSelected('')
      }
    },
    regionRemoved () {
      this.selectedCountry = 0
      // this.$emit('selected-country', this.selectedCountry)
    },
    countrySelected (country) {
      this.selectedCountry = country
      this.$emit('selected-country', this.selectedCountry)
      if (!this.filter('districts')) return

      if (this.currentUser.can('Districts', 'index')) {
        this.asyncFindDistrict('')
      } else {
        this.isLoading.districts = true
        if (this.currentUser.district) {
          this.districtsList = [
            this.currentUser.district,
          ]
          this.selectedDistrict = this.currentUser.district
          this.districtSelected(this.selectedDistrict)
        }
        this.isLoading.districts = false
      }

      if (!!this.selectedDistrict && this.selectedDistrict.country_id !== country.id) {
        this.districtSelected('')
      }
    },
    countryRemoved () {
      // this.districtSelected(0)
      this.selectedDistrict = 0
    },
    districtSelected (district) {
      this.selectedDistrict = district
      this.$emit('selected-district', this.selectedDistrict)
    },
  },
}
</script>
